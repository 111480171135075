export const BusinessSectors = [
  { category: 'A', code: '', description: $localize `:@@business_sectors.category.a:Agriculture, forestry and fishing` },
  { category: 'A', code: '01', description: $localize `:@@business_sectors.code.1:Crop and animal production, hunting and related service activities` },
  { category: 'A', code: '02', description: $localize `:@@business_sectors.code.2:Forestry and logging` },
  { category: 'A', code: '03', description: $localize `:@@business_sectors.code.3:Fishing and aquaculture` },

  { category: 'B', code: '', description: $localize `:@@business_sectors.category.b:Mining and quarrying` },
  { category: 'B', code: '05', description: $localize `:@@business_sectors.code.5:Mining of coal and lignite` },
  { category: 'B', code: '06', description: $localize `:@@business_sectors.code.6:Extraction of crude petroleum and natural gas` },
  { category: 'B', code: '07', description: $localize `:@@business_sectors.code.7:Mining of metal ores` },
  { category: 'B', code: '08', description: $localize `:@@business_sectors.code.8:Other mining and quarrying` },
  { category: 'B', code: '09', description: $localize `:@@business_sectors.code.9:Mining support service activities` },

  { category: 'C', code: '', description: $localize `:@@business_sectors.category.c:Manufacturing` },
  { category: 'C', code: '10', description: $localize `:@@business_sectors.code.10:Manufacture of food products` },
  { category: 'C', code: '11', description: $localize `:@@business_sectors.code.11:Manufacture of beverages` },
  { category: 'C', code: '12', description: $localize `:@@business_sectors.code.12:Manufacture of tobacco products` },
  { category: 'C', code: '13', description: $localize `:@@business_sectors.code.13:Manufacture of textiles` },
  { category: 'C', code: '14', description: $localize `:@@business_sectors.code.14:Manufacture of wearing apparel` },
  { category: 'C', code: '15', description: $localize `:@@business_sectors.code.15:Manufacture of leather and related products` },
  { category: 'C', code: '16', description: $localize `:@@business_sectors.code.16:Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials` },
  { category: 'C', code: '17', description: $localize `:@@business_sectors.code.17:Manufacture of paper and paper products` },
  { category: 'C', code: '18', description: $localize `:@@business_sectors.code.18:Printing and reproduction of recorded media` },
  { category: 'C', code: '19', description: $localize `:@@business_sectors.code.19:Manufacture of coke and refined petroleum products` },
  { category: 'C', code: '20', description: $localize `:@@business_sectors.code.20:Manufacture of chemicals and chemical products` },
  { category: 'C', code: '21', description: $localize `:@@business_sectors.code.21:Manufacture of basic pharmaceutical products and pharmaceutical preparations` },
  { category: 'C', code: '22', description: $localize `:@@business_sectors.code.22:Manufacture of rubber and plastics products` },
  { category: 'C', code: '23', description: $localize `:@@business_sectors.code.23:Manufacture of other non-metallic mineral products` },
  { category: 'C', code: '24', description: $localize `:@@business_sectors.code.24:Manufacture of basic metals` },
  { category: 'C', code: '25', description: $localize `:@@business_sectors.code.25:Manufacture of fabricated metal products, except machinery and equipment` },
  { category: 'C', code: '26', description: $localize `:@@business_sectors.code.26:Manufacture of computer, electronic and optical products` },
  { category: 'C', code: '27', description: $localize `:@@business_sectors.code.27:Manufacture of electrical equipment` },
  { category: 'C', code: '28', description: $localize `:@@business_sectors.code.28:Manufacture of machinery and equipment n.e.c.` },
  { category: 'C', code: '29', description: $localize `:@@business_sectors.code.29:Manufacture of motor vehicles, trailers and semi-trailers` },
  { category: 'C', code: '30', description: $localize `:@@business_sectors.code.30:Manufacture of other transport equipment` },
  { category: 'C', code: '31', description: $localize `:@@business_sectors.code.31:Manufacture of furniture` },
  { category: 'C', code: '32', description: $localize `:@@business_sectors.code.32:Other manufacturing` },
  { category: 'C', code: '33', description: $localize `:@@business_sectors.code.33:Repair and installation of machinery and equipment` },

  { category: 'D', code: '', description: $localize `:@@business_sectors.category.d:Electricity, gas, steam and air conditioning supply` },
  { category: 'D', code: '351', description: $localize `:@@business_sectors.code.351:Electric power generation, transmission and distribution` },
  { category: 'D', code: '352', description: $localize `:@@business_sectors.code.352:Manufacture of gas; distribution of gaseous fuels through mains` },
  { category: 'D', code: '353', description: $localize `:@@business_sectors.code.353:Steam and air conditioning supply` },

  { category: 'E', code: '', description: $localize `:@@business_sectors.category.e:Water supply; sewerage, waste management and remediation activities` },
  { category: 'E', code: '36', description: $localize `:@@business_sectors.code.36:Water collection, treatment and supply` },
  { category: 'E', code: '37', description: $localize `:@@business_sectors.code.37:Sewerage` },
  { category: 'E', code: '38', description: $localize `:@@business_sectors.code.38:Waste collection, treatment and disposal activities; materials recovery` },
  { category: 'E', code: '39', description: $localize `:@@business_sectors.code.39:Remediation activities and other waste management services` },

  { category: 'F', code: '', description: $localize `:@@business_sectors.category.f:Construction` },
  { category: 'F', code: '41', description: $localize `:@@business_sectors.code.41:Construction of buildings` },
  { category: 'F', code: '42', description: $localize `:@@business_sectors.code.42:Civil engineering` },
  { category: 'F', code: '43', description: $localize `:@@business_sectors.code.43:Specialized construction activities` },

  { category: 'G', code: '', description: $localize `:@@business_sectors.category.g:Wholesale and retail trade; repair of motor vehicles and motorcycles` },
  { category: 'G', code: '45', description: $localize `:@@business_sectors.code.45:Wholesale and retail trade and repair of motor vehicles and motorcycles` },
  { category: 'G', code: '46', description: $localize `:@@business_sectors.code.46:Wholesale trade, except of motor vehicles and motorcycles` },
  { category: 'G', code: '47', description: $localize `:@@business_sectors.code.47:Retail trade, except of motor vehicles and motorcycles` },

  { category: 'H', code: '', description: $localize `:@@business_sectors.category.h:Transportation and storage` },
  { category: 'H', code: '49', description: $localize `:@@business_sectors.code.49:Land transport and transport via pipelines` },
  { category: 'H', code: '50', description: $localize `:@@business_sectors.code.50:Water transport` },
  { category: 'H', code: '51', description: $localize `:@@business_sectors.code.51:Air transport` },
  { category: 'H', code: '52', description: $localize `:@@business_sectors.code.52:Warehousing and support activities for transportation` },
  { category: 'H', code: '53', description: $localize `:@@business_sectors.code.53:Postal and courier activities` },

  { category: 'I', code: '', description: $localize `:@@business_sectors.category.i:Accommodation and food service activities` },
  { category: 'I', code: '55', description: $localize `:@@business_sectors.code.55:Accommodation` },
  { category: 'I', code: '56', description: $localize `:@@business_sectors.code.56:Food and beverage service activities` },

  { category: 'J', code: '', description: $localize `:@@business_sectors.category.j:Information and communication` },
  { category: 'J', code: '58', description: $localize `:@@business_sectors.code.58:Publishing activities` },
  { category: 'J', code: '59', description: $localize `:@@business_sectors.code.59:Motion picture, video and television programme production, sound recording and music publishing activities` },
  { category: 'J', code: '60', description: $localize `:@@business_sectors.code.60:Programming and broadcasting activities` },
  { category: 'J', code: '61', description: $localize `:@@business_sectors.code.61:Telecommunications` },
  { category: 'J', code: '62', description: $localize `:@@business_sectors.code.62:Computer programming, consultancy and related activities` },
  { category: 'J', code: '63', description: $localize `:@@business_sectors.code.63:Information service activities` },

  { category: 'K', code: '', description: $localize `:@@business_sectors.category.k:Financial and insurance activities` },
  { category: 'K', code: '64', description: $localize `:@@business_sectors.code.64:Financial service activities, except insurance and pension funding` },
  { category: 'K', code: '65', description: $localize `:@@business_sectors.code.65:Insurance, reinsurance and pension funding, except compulsory social security` },
  { category: 'K', code: '66', description: $localize `:@@business_sectors.code.66:Activities auxiliary to financial service and insurance activities` },

  { category: 'L', code: '', description: $localize `:@@business_sectors.category.l:Real estate activities` },
  { category: 'L', code: '68', description: $localize `:@@business_sectors.code.68:Real estate activities` },

  { category: 'M', code: '', description: $localize `:@@business_sectors.category.m:Professional, scientific and technical activities` },
  { category: 'M', code: '69', description: $localize `:@@business_sectors.code.69:Legal and accounting activities` },
  { category: 'M', code: '70', description: $localize `:@@business_sectors.code.70:Activities of head offices; management consultancy activities` },
  { category: 'M', code: '71', description: $localize `:@@business_sectors.code.71:Architectural and engineering activities; technical testing and analysis` },
  { category: 'M', code: '72', description: $localize `:@@business_sectors.code.72:Scientific research and development` },
  { category: 'M', code: '73', description: $localize `:@@business_sectors.code.73:Advertising and market research` },
  { category: 'M', code: '74', description: $localize `:@@business_sectors.code.74:Other professional, scientific and technical activities` },
  { category: 'M', code: '75', description: $localize `:@@business_sectors.code.75:Veterinary activities` },

  { category: 'N', code: '', description: $localize `:@@business_sectors.category.n:Administrative and support service activities` },
  { category: 'N', code: '77', description: $localize `:@@business_sectors.code.77:Rental and leasing activities` },
  { category: 'N', code: '78', description: $localize `:@@business_sectors.code.78:Employment activities` },
  { category: 'N', code: '79', description: $localize `:@@business_sectors.code.79:Travel agency, tour operator, reservation service and related activities` },
  { category: 'N', code: '80', description: $localize `:@@business_sectors.code.80:Security and investigation activities` },
  { category: 'N', code: '81', description: $localize `:@@business_sectors.code.81:Services to buildings and landscape activities` },
  { category: 'N', code: '82', description: $localize `:@@business_sectors.code.82:Office administrative, office support and other business support activities` },

  { category: 'O', code: '', description: $localize `:@@business_sectors.category.o:Public administration and defence; compulsory social security` },

  { category: 'P', code: '', description: $localize `:@@business_sectors.category.p:Education` },

  { category: 'Q', code: '', description: $localize `:@@business_sectors.category.q:Human health and social work activities` },
  { category: 'Q', code: '86', description: $localize `:@@business_sectors.code.86:Human health activities` },
  { category: 'Q', code: '87', description: $localize `:@@business_sectors.code.87:Residential care activities` },
  { category: 'Q', code: '88', description: $localize `:@@business_sectors.code.88:Social work activities without accommodation` },

  { category: 'R', code: '', description: $localize `:@@business_sectors.category.r:Arts, entertainment and recreation` },
  { category: 'R', code: '90', description: $localize `:@@business_sectors.code.90:Creative, arts and entertainment activities` },
  { category: 'R', code: '91', description: $localize `:@@business_sectors.code.91:Libraries, archives, museums and other cultural activities` },
  { category: 'R', code: '92', description: $localize `:@@business_sectors.code.92:Gambling and betting activities` },
  { category: 'R', code: '93', description: $localize `:@@business_sectors.code.93:Sports activities and amusement and recreation activities` },

  { category: 'S', code: '', description: $localize `:@@business_sectors.category.s:Other service activities` },
  { category: 'S', code: '94', description: $localize `:@@business_sectors.code.94:Activities of membership organizations` },
  { category: 'S', code: '95', description: $localize `:@@business_sectors.code.95:Repair of computers and personal and household goods` },
  { category: 'S', code: '96', description: $localize `:@@business_sectors.code.96:Other personal service activities` },

  { category: 'T', code: '', description: $localize `:@@business_sectors.category.t:Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use` },
  { category: 'T', code: '97', description: $localize `:@@business_sectors.code.97:Activities of households as employers of domestic personnel` },
  { category: 'T', code: '98', description: $localize `:@@business_sectors.code.98:Undifferentiated goods- and services-producing activities of private households for own use` },

  { category: 'U', code: '', description: $localize `:@@business_sectors.category.u:Activities of extraterritorial organizations and bodies` },
];
