export const Locales = [
  { code: 'CH', name: $localize `:@@countries.CH:Switzerland` },
  { code: 'DE', name: $localize `:@@countries.DE:Germany` },
  { code: 'AT', name: $localize `:@@countries.AT:Austria` },

  { code: 'AD', name: $localize `:@@countries.AD:Andorra` },
  { code: 'AE', name: $localize `:@@countries.AE:United Arab Emirates` },
  { code: 'AF', name: $localize `:@@countries.AF:Afghanistan` },
  { code: 'AG', name: $localize `:@@countries.AG:Antigua and Barbuda` },
  { code: 'AI', name: $localize `:@@countries.AI:Anguilla` },
  { code: 'AL', name: $localize `:@@countries.AL:Albania` },
  { code: 'AM', name: $localize `:@@countries.AM:Armenia` },
  { code: 'AN', name: $localize `:@@countries.AN:Netherlands Antilles` },
  { code: 'AO', name: $localize `:@@countries.AO:Angola` },
  { code: 'AQ', name: $localize `:@@countries.AQ:Antarctic` },
  { code: 'AR', name: $localize `:@@countries.AR:Argentina` },
  { code: 'AS', name: $localize `:@@countries.AS:American Samoa` },
  { code: 'AU', name: $localize `:@@countries.AU:Australia` },
  { code: 'AW', name: $localize `:@@countries.AW:Aruba` },
  { code: 'AX', name: $localize `:@@countries.AX:Aland Islands` },
  { code: 'AZ', name: $localize `:@@countries.AZ:Azerbaijan` },
  { code: 'BA', name: $localize `:@@countries.BA:Bosnia and Herzegovina` },
  { code: 'BB', name: $localize `:@@countries.BB:Barbados` },
  { code: 'BD', name: $localize `:@@countries.BD:Bangladesh` },
  { code: 'BE', name: $localize `:@@countries.BE:Belgium` },
  { code: 'BF', name: $localize `:@@countries.BF:Burkina Faso` },
  { code: 'BG', name: $localize `:@@countries.BG:Bulgaria` },
  { code: 'BH', name: $localize `:@@countries.BH:Bahrain` },
  { code: 'BI', name: $localize `:@@countries.BI:Burundi` },
  { code: 'BJ', name: $localize `:@@countries.BJ:Benin` },
  { code: 'BL', name: $localize `:@@countries.BL:Saint-Barthélemy` },
  { code: 'BM', name: $localize `:@@countries.BM:Bermuda` },
  { code: 'BN', name: $localize `:@@countries.BN:Brunei` },
  { code: 'BO', name: $localize `:@@countries.BO:Bolivia` },
  { code: 'BQ', name: $localize `:@@countries.BQ:Bonaire, St. Eustatius and Saba` },
  { code: 'BR', name: $localize `:@@countries.BR:Brazil` },
  { code: 'BS', name: $localize `:@@countries.BS:Bahamas` },
  { code: 'BT', name: $localize `:@@countries.BT:Bhutan` },
  { code: 'BV', name: $localize `:@@countries.BV:Bouvet Island` },
  { code: 'BW', name: $localize `:@@countries.BW:Botswana` },
  { code: 'BY', name: $localize `:@@countries.BY:Belarus` },
  { code: 'BZ', name: $localize `:@@countries.BZ:Belize` },
  { code: 'CA', name: $localize `:@@countries.CA:Canada` },
  { code: 'CC', name: $localize `:@@countries.CC:Cocos Islands` },
  { code: 'CD', name: $localize `:@@countries.CD:Democratic Republic of Congo` },
  { code: 'CF', name: $localize `:@@countries.CF:Central African Republic` },
  { code: 'CG', name: $localize `:@@countries.CG:Republic of the Congo` },
  { code: 'CI', name: $localize `:@@countries.CI:Ivory Coast` },
  { code: 'CK', name: $localize `:@@countries.CK:Cook Islands` },
  { code: 'CL', name: $localize `:@@countries.CL:Chile` },
  { code: 'CM', name: $localize `:@@countries.CM:Cameroon` },
  { code: 'CN', name: $localize `:@@countries.CN:China` },
  { code: 'CO', name: $localize `:@@countries.CO:Colombia` },
  { code: 'CR', name: $localize `:@@countries.CR:Costa Rica` },
  { code: 'CU', name: $localize `:@@countries.CU:Cuba` },
  { code: 'CV', name: $localize `:@@countries.CV:Cape Verde` },
  { code: 'CW', name: $localize `:@@countries.CW:Curacao` },
  { code: 'CX', name: $localize `:@@countries.CX:Christmas Island` },
  { code: 'CY', name: $localize `:@@countries.CY:Cyprus` },
  { code: 'CZ', name: $localize `:@@countries.CZ:Czech Republic` },
  { code: 'DJ', name: $localize `:@@countries.DJ:Djibouti` },
  { code: 'DK', name: $localize `:@@countries.DK:Denmark` },
  { code: 'DM', name: $localize `:@@countries.DM:Dominica` },
  { code: 'DO', name: $localize `:@@countries.DO:Dominican Republic` },
  { code: 'DZ', name: $localize `:@@countries.DZ:Algeria` },
  { code: 'EC', name: $localize `:@@countries.EC:Ecuador` },
  { code: 'EE', name: $localize `:@@countries.EE:Estonia` },
  { code: 'EG', name: $localize `:@@countries.EG:Egypt` },
  { code: 'EH', name: $localize `:@@countries.EH:Western Sahara` },
  { code: 'ER', name: $localize `:@@countries.ER:Eritrea` },
  { code: 'ES', name: $localize `:@@countries.ES:Spain` },
  { code: 'ET', name: $localize `:@@countries.ET:Ethiopia` },
  { code: 'FI', name: $localize `:@@countries.FI:Finland` },
  { code: 'FJ', name: $localize `:@@countries.FJ:Fiji` },
  { code: 'FK', name: $localize `:@@countries.FK:Falkland Islands` },
  { code: 'FM', name: $localize `:@@countries.FM:Federated States of Micronesia` },
  { code: 'FO', name: $localize `:@@countries.FO:Faroe Islands` },
  { code: 'FR', name: $localize `:@@countries.FR:France` },
  { code: 'GA', name: $localize `:@@countries.GA:Gabon` },
  { code: 'GB', name: $localize `:@@countries.GB:United Kingdom` },
  { code: 'GD', name: $localize `:@@countries.GD:Grenada` },
  { code: 'GE', name: $localize `:@@countries.GE:Georgia` },
  { code: 'GF', name: $localize `:@@countries.GF:French Guiana` },
  { code: 'GG', name: $localize `:@@countries.GG:Guernsey` },
  { code: 'GH', name: $localize `:@@countries.GH:Ghana` },
  { code: 'GI', name: $localize `:@@countries.GI:Gibraltar` },
  { code: 'GL', name: $localize `:@@countries.GL:Greenland` },
  { code: 'GM', name: $localize `:@@countries.GM:Gambia` },
  { code: 'GN', name: $localize `:@@countries.GN:Guinea` },
  { code: 'GP', name: $localize `:@@countries.GP:Guadeloupe` },
  { code: 'GQ', name: $localize `:@@countries.GQ:Equatorial Guinea` },
  { code: 'GR', name: $localize `:@@countries.GR:Greece` },
  { code: 'GS', name: $localize `:@@countries.GS:South Georgia and the South Sandwich Islands` },
  { code: 'GT', name: $localize `:@@countries.GT:Guatemala` },
  { code: 'GU', name: $localize `:@@countries.GU:Guam` },
  { code: 'GW', name: $localize `:@@countries.GW:Guinea-Bissau` },
  { code: 'GY', name: $localize `:@@countries.GY:Guyana` },
  { code: 'HK', name: $localize `:@@countries.HK:Hong Kong` },
  { code: 'HM', name: $localize `:@@countries.HM:Heard and McDonald Islands` },
  { code: 'HN', name: $localize `:@@countries.HN:Honduras` },
  { code: 'HR', name: $localize `:@@countries.HR:Croatia` },
  { code: 'HT', name: $localize `:@@countries.HT:Haiti` },
  { code: 'HU', name: $localize `:@@countries.HU:Hungary` },
  { code: 'ID', name: $localize `:@@countries.ID:Indonesia` },
  { code: 'IE', name: $localize `:@@countries.IE:Ireland` },
  { code: 'IL', name: $localize `:@@countries.IL:Israel` },
  { code: 'IM', name: $localize `:@@countries.IM:Isle of Man` },
  { code: 'IN', name: $localize `:@@countries.IN:India` },
  { code: 'IO', name: $localize `:@@countries.IO:British Territory in Indian Ozean` },
  { code: 'IQ', name: $localize `:@@countries.IQ:Iraq` },
  { code: 'IR', name: $localize `:@@countries.IR:Iran` },
  { code: 'IS', name: $localize `:@@countries.IS:Iceland` },
  { code: 'IT', name: $localize `:@@countries.IT:Italy` },
  { code: 'JE', name: $localize `:@@countries.JE:Jersey` },
  { code: 'JM', name: $localize `:@@countries.JM:Jamaica` },
  { code: 'JO', name: $localize `:@@countries.JO:Jordan` },
  { code: 'JP', name: $localize `:@@countries.JP:Japan` },
  { code: 'KE', name: $localize `:@@countries.KE:Kenya` },
  { code: 'KG', name: $localize `:@@countries.KG:Kyrgyzstan` },
  { code: 'KH', name: $localize `:@@countries.KH:Cambodia` },
  { code: 'KI', name: $localize `:@@countries.KI:Kiribati` },
  { code: 'KM', name: $localize `:@@countries.KM:Comoros` },
  { code: 'KN', name: $localize `:@@countries.KN:St. Kitts and Nevis` },
  { code: 'KP', name: $localize `:@@countries.KP:North Korea` },
  { code: 'KR', name: $localize `:@@countries.KR:South Korea` },
  { code: 'KW', name: $localize `:@@countries.KW:Kuwait` },
  { code: 'KY', name: $localize `:@@countries.KY:Cayman Islands` },
  { code: 'KZ', name: $localize `:@@countries.KZ:Kazakhstan` },
  { code: 'LA', name: $localize `:@@countries.LA:Laos` },
  { code: 'LB', name: $localize `:@@countries.LB:Lebanon` },
  { code: 'LC', name: $localize `:@@countries.LC:St. Lucia ` },
  { code: 'LI', name: $localize `:@@countries.LI:Liechtenstein` },
  { code: 'LK', name: $localize `:@@countries.LK:Sri Lanka` },
  { code: 'LR', name: $localize `:@@countries.LR:Liberia` },
  { code: 'LS', name: $localize `:@@countries.LS:Lesotho` },
  { code: 'LT', name: $localize `:@@countries.LT:Lithuania` },
  { code: 'LU', name: $localize `:@@countries.LU:Luxembourg` },
  { code: 'LV', name: $localize `:@@countries.LV:Latvia` },
  { code: 'LY', name: $localize `:@@countries.LY:Libya` },
  { code: 'MA', name: $localize `:@@countries.MA:Morocco` },
  { code: 'MC', name: $localize `:@@countries.MC:Monaco` },
  { code: 'MD', name: $localize `:@@countries.MD:Moldova` },
  { code: 'ME', name: $localize `:@@countries.ME:Montenegro` },
  { code: 'MF', name: $localize `:@@countries.MF:St. Martin ` },
  { code: 'MG', name: $localize `:@@countries.MG:Madagascar` },
  { code: 'MH', name: $localize `:@@countries.MH:Marshall Islands` },
  { code: 'MK', name: $localize `:@@countries.MK:Macedonia` },
  { code: 'ML', name: $localize `:@@countries.ML:Mali` },
  { code: 'MM', name: $localize `:@@countries.MM:Myanmar` },
  { code: 'MN', name: $localize `:@@countries.MN:Mongolia` },
  { code: 'MO', name: $localize `:@@countries.MO:Macau` },
  { code: 'MP', name: $localize `:@@countries.MP:Northern Mariana Islands` },
  { code: 'MQ', name: $localize `:@@countries.MQ:Martinique` },
  { code: 'MR', name: $localize `:@@countries.MR:Mauritania` },
  { code: 'MS', name: $localize `:@@countries.MS:Montserrat` },
  { code: 'MT', name: $localize `:@@countries.MT:Malta` },
  { code: 'MU', name: $localize `:@@countries.MU:Mauritius` },
  { code: 'MV', name: $localize `:@@countries.MV:Maldives` },
  { code: 'MW', name: $localize `:@@countries.MW:Malawi` },
  { code: 'MX', name: $localize `:@@countries.MX:Mexico` },
  { code: 'MY', name: $localize `:@@countries.MY:Malaysia` },
  { code: 'MZ', name: $localize `:@@countries.MZ:Mozambique` },
  { code: 'NA', name: $localize `:@@countries.NA:Namibia` },
  { code: 'NC', name: $localize `:@@countries.NC:New Caledonia` },
  { code: 'NE', name: $localize `:@@countries.NE:Niger` },
  { code: 'NF', name: $localize `:@@countries.NF:Norfolk Island` },
  { code: 'NG', name: $localize `:@@countries.NG:Nigeria` },
  { code: 'NI', name: $localize `:@@countries.NI:Nicaragua` },
  { code: 'NL', name: $localize `:@@countries.NL:Netherlands` },
  { code: 'NO', name: $localize `:@@countries.NO:Norway` },
  { code: 'NP', name: $localize `:@@countries.NP:Nepal` },
  { code: 'NR', name: $localize `:@@countries.NR:Nauru` },
  { code: 'NU', name: $localize `:@@countries.NU:Niue` },
  { code: 'NZ', name: $localize `:@@countries.NZ:New Zealand` },
  { code: 'OM', name: $localize `:@@countries.OM:Oman` },
  { code: 'PA', name: $localize `:@@countries.PA:Panama` },
  { code: 'PE', name: $localize `:@@countries.PE:Peru` },
  { code: 'PF', name: $localize `:@@countries.PF:French Polynesia` },
  { code: 'PG', name: $localize `:@@countries.PG:Papua New Guinea` },
  { code: 'PH', name: $localize `:@@countries.PH:Philippines` },
  { code: 'PK', name: $localize `:@@countries.PK:Pakistan` },
  { code: 'PL', name: $localize `:@@countries.PL:Poland` },
  { code: 'PM', name: $localize `:@@countries.PM:Saint-Pierre and Miquelon` },
  { code: 'PN', name: $localize `:@@countries.PN:Pitcairn Islands` },
  { code: 'PR', name: $localize `:@@countries.PR:Puerto Rico` },
  { code: 'PS', name: $localize `:@@countries.PS:Palestinian Territories` },
  { code: 'PT', name: $localize `:@@countries.PT:Portugal` },
  { code: 'PW', name: $localize `:@@countries.PW:Palau` },
  { code: 'PY', name: $localize `:@@countries.PY:Paraguay` },
  { code: 'QA', name: $localize `:@@countries.QA:Qatar` },
  { code: 'RE', name: $localize `:@@countries.RE:Reunion` },
  { code: 'RO', name: $localize `:@@countries.RO:Romania` },
  { code: 'RS', name: $localize `:@@countries.RS:Serbia` },
  { code: 'RU', name: $localize `:@@countries.RU:Russia` },
  { code: 'RW', name: $localize `:@@countries.RW:Rwanda` },
  { code: 'SA', name: $localize `:@@countries.SA:Saudi Arabia` },
  { code: 'SB', name: $localize `:@@countries.SB:Solomon Islands` },
  { code: 'SC', name: $localize `:@@countries.SC:Seychelles` },
  { code: 'SD', name: $localize `:@@countries.SD:Sudan` },
  { code: 'SE', name: $localize `:@@countries.SE:Sweden` },
  { code: 'SG', name: $localize `:@@countries.SG:Singapore` },
  { code: 'SH', name: $localize `:@@countries.SH:St. Helena ` },
  { code: 'SI', name: $localize `:@@countries.SI:Slovenia` },
  { code: 'SJ', name: $localize `:@@countries.SJ:Svalbard and Jan Mayen` },
  { code: 'SK', name: $localize `:@@countries.SK:Slovakia` },
  { code: 'SL', name: $localize `:@@countries.SL:Sierra Leone` },
  { code: 'SM', name: $localize `:@@countries.SM:San Marino` },
  { code: 'SN', name: $localize `:@@countries.SN:Senegal` },
  { code: 'SO', name: $localize `:@@countries.SO:Somalia` },
  { code: 'SR', name: $localize `:@@countries.SR:Suriname` },
  { code: 'SS', name: $localize `:@@countries.SS:Sudan` },
  { code: 'ST', name: $localize `:@@countries.ST:São Tomé and Príncipe` },
  { code: 'SV', name: $localize `:@@countries.SV:El Salvador` },
  { code: 'SX', name: $localize `:@@countries.SX:Sint Maarten` },
  { code: 'SY', name: $localize `:@@countries.SY:Syria` },
  { code: 'SZ', name: $localize `:@@countries.SZ:Eswatini` },
  { code: 'TC', name: $localize `:@@countries.TC:Turks and Caicos Islands` },
  { code: 'TD', name: $localize `:@@countries.TD:Chad` },
  { code: 'TF', name: $localize `:@@countries.TF:French Southern and Antarctic Lands` },
  { code: 'TG', name: $localize `:@@countries.TG:Togo` },
  { code: 'TH', name: $localize `:@@countries.TH:Thailand` },
  { code: 'TJ', name: $localize `:@@countries.TJ:Tajikistan` },
  { code: 'TK', name: $localize `:@@countries.TK:Tokelau` },
  { code: 'TL', name: $localize `:@@countries.TL:Timor` },
  { code: 'TM', name: $localize `:@@countries.TM:Turkmenistan` },
  { code: 'TN', name: $localize `:@@countries.TN:Tunisia` },
  { code: 'TO', name: $localize `:@@countries.TO:Tonga` },
  { code: 'TR', name: $localize `:@@countries.TR:Turkey` },
  { code: 'TT', name: $localize `:@@countries.TT:Trinidad and Tobago` },
  { code: 'TV', name: $localize `:@@countries.TV:Tuvalu` },
  { code: 'TW', name: $localize `:@@countries.TW:Taiwan` },
  { code: 'TZ', name: $localize `:@@countries.TZ:Tanzania` },
  { code: 'UA', name: $localize `:@@countries.UA:Ukraine` },
  { code: 'UG', name: $localize `:@@countries.UG:Uganda` },
  { code: 'UM', name: $localize `:@@countries.UM:United States Minor Outlying Islands` },
  { code: 'US', name: $localize `:@@countries.US:United States of America` },
  { code: 'UY', name: $localize `:@@countries.UY:Uruguay` },
  { code: 'UZ', name: $localize `:@@countries.UZ:Uzbekistan` },
  { code: 'VA', name: $localize `:@@countries.VA:Vatican City` },
  { code: 'VC', name: $localize `:@@countries.VC:St. Vincent and the Grenadines` },
  { code: 'VE', name: $localize `:@@countries.VE:Venezuela` },
  { code: 'VG', name: $localize `:@@countries.VG:British Virgin Islands` },
  { code: 'VI', name: $localize `:@@countries.VI:American Virgin Islands` },
  { code: 'VN', name: $localize `:@@countries.VN:Vietnam` },
  { code: 'VU', name: $localize `:@@countries.VU:Vanuatu` },
  { code: 'WF', name: $localize `:@@countries.WF:Wallis and Futuna` },
  { code: 'WS', name: $localize `:@@countries.WS:Samoa` },
  { code: 'XK', name: $localize `:@@countries.XK:Kosovo` },
  { code: 'YE', name: $localize `:@@countries.YE:Yemen` },
  { code: 'YT', name: $localize `:@@countries.YT:Mayotte` },
  { code: 'ZA', name: $localize `:@@countries.ZA:South Africa` },
  { code: 'ZM', name: $localize `:@@countries.ZM:Zambia` },
  { code: 'ZW', name: $localize `:@@countries.ZW:Zimbabwe` },

  // continents
  { code: 'XA', name: $localize `:@@continents.AF:` },
  { code: 'XB', name: $localize `:@@continents.NA:` },
  { code: 'XC', name: $localize `:@@continents.OC:` },
  { code: 'XD', name: $localize `:@@continents.AN:` },
  { code: 'XE', name: $localize `:@@continents.XB:Asia` },
  { code: 'XI', name: $localize `:@@continents.XA:Europe` },

  // oceans
  { code: 'XK', name: $localize `:@@continents.XK:Atlantic Ocean` },
  { code: 'XL', name: $localize `:@@continents.XL:Indian Ocean` },
  { code: 'XM', name: $localize `:@@continents.XM:Pacific Ocean` },

  // bonus
  { code: 'XQ', name: $localize `:@@continents.XQ:world-wide` }
];
