import { Injectable } from '@angular/core';
import { BusinessSectors } from '@parashift/shared/constants';
import { BusinessSector, Option } from '@parashift/shared/models';

@Injectable({
  providedIn: 'root'
})
export class BusinessSectorsRegister {
  public data: BusinessSector[] = [];
  public originData: BusinessSector[] = [];

  constructor() {
    this.initRegister();
  }

  private initRegister() {
    this.data = [];
    BusinessSectors.forEach((bs: BusinessSector) => {
      this.originData.push({ category: bs.category, code: bs.code, description: bs.description });
      bs.search_index = bs.category + (bs.code ? ' ' + bs.code : '') + ': ' + bs.description;
      this.data.push(bs);
    });
  }

  getByCode(category: string, code: string) {
    return this.data.find(obj => obj.category === category && obj.code === code);
  }

  getSelectOptions(emptyOption = true): Option[] {
    const options = emptyOption ? [{ value: '', label: $localize `:@@shared.filter_column.not_set:Not set`, group: '' }] : [];

    this.originData.forEach(bs => {
      options.push({
        value: bs.category + bs.code,
        label: bs.category + ' ' + bs.code,
        group: $localize `:@@common.business_sectors:Business Sectors`
      });
    });

    return options;
  }

  getFilterItems() {
    return this.originData.map(bs => {
      return {
        key: bs.category + '-' + (bs.code || ''),
        value: bs.category + (bs.code ? ' ' + bs.code : '') + ': ' + bs.description,
        tagDisplay: bs.category + (bs.code ? '|' + bs.code : ''),
        search_index: bs.category + (bs.code ? ' ' + bs.code : '') + ': ' + bs.description,
        tooltip: bs.description
      };
    });
  }
}
