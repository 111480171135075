import { Injectable } from '@angular/core';
import { Locales } from '@parashift/shared/constants';
import { Locale, Option } from '@parashift/shared/models';

@Injectable({
  providedIn: 'root'
})
export class LocalesRegister {
  public data: Locale[] = [];
  public originData: Locale[] = [];

  constructor() {
    this.initRegister();
  }

  private initRegister() {
    this.data = [];
    Locales.forEach((locale: Locale) => {
      this.originData.push({ code: locale.code, name: locale.name });
      locale.search_index = locale.code + ': ' + locale.name;
      this.data.push(locale);
    });
  }

  getByCode(code: string) {
    return this.data.find(obj => obj.code === code);
  }

  getSelectOptions(emptyOption = true): Option[] {
    const options = emptyOption ? [{ value: '', label: $localize `:@@shared.filter_column.not_set:Not set`, group: '' }] : [];

    this.originData.forEach(locale => {
      options.push({
        value: locale.code,
        label: locale.code,
        group: $localize `:@@common.locales:Locales`
      });
    });

    return options;
  }

  getFilterItems() {
    return this.originData.map(locale => {
      return {
        key: locale.code,
        value: locale.code,
        search_index: locale.code + ': ' + locale.name,
        tooltip: locale.name
      };
    });
  }
}
